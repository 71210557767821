
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileField,
  FileInput,
} from "react-admin";

export const PartenaireList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="vendor_id" />
      <TextField source="nom" />
      <FileField source="logo" />
      <TextField source="lien_redirection" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const PartenaireEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="nom" />
      <FileInput source="logo" />
      <TextInput source="lien_redirection" />
    </SimpleForm>
  </Edit>
);

export const PartenaireCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="nom" />
      <FileInput source="logo" />
      <TextInput source="lien_redirection" />
    </SimpleForm>
  </Create>
);
