import * as React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton, Edit, SimpleForm, TextInput, Create,FileInput } from 'react-admin';

export const ServiceList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="nom" />
            <TextField source="description" />
            <TextField source="responsable" />
            <TextField source="adresse" />
            <TextField source="horaire" />
            <TextField source="contact" />
            <TextField source="Media_images" />
            <TextField source="Media_videos" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const ServiceEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nom" />
            <TextInput source="description" />
            <TextInput source="responsable" />
            <TextInput source="adresse" />
            <TextInput source="horaire" />
            <TextInput source="contact" />
            <TextInput source="Media_images" />
            <TextInput source="Media_videos" /> 
        </SimpleForm>
    </Edit>
);

export const ServiceCreate = props => (
    <Create {...props}>
        <SimpleForm>
        <TextInput source="nom" />
            <TextInput source="description" />
            <TextInput source="responsable" />
            <TextInput source="adresse" />
            <TextInput source="horaire" />
            <TextInput source="contact" />
            <FileInput source="Media_images" />
            <FileInput source="Media_videos" /> 
        </SimpleForm>
    </Create>
);
