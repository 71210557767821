import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  DateField,
  FileField,
  ImageField,
} from 'react-admin';
import environment from './config/environment';

export const ArticleList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="titre" />
      <TextField source="description" />
      <ImageField source="media_images" label="Image" />
      <FileField source="media_videos" label="Video" />
      <TextField source="auteur" />
      <DateField source="date" />
      <TextField source="contenu" />
      <TextField source="type_article_id" label="Type d'Article" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const ArticleCreate = (props) => {
  const [typeChoices, setTypeChoices] = useState([]);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await fetch(`${environment.apiUrl}/type_article`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const types = await response.json();
        setTypeChoices(types.map((type) => ({ id: type.id, name: type.nom })));
      } catch (error) {
        console.error('Failed to fetch type articles:', error);
      }
    };
    fetchTypes();
  }, []);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('auteur', values.auteur);
    formData.append('contenu', values.contenu);
    formData.append('description', values.description);
    formData.append('titre', values.titre);
    formData.append('type_article_id', values.type_article_id);
    if (values.media_images instanceof File) {
      formData.append(
        'media_images',
        values.media_images,
        values.media_images.name,
      );
    } else if (
      values.media_images &&
      values.media_images.rawFile instanceof File
    ) {
      formData.append(
        'media_images',
        values.media_images.rawFile,
        values.media_images.rawFile.name,
      );
    }
    if (values.media_videos instanceof File) {
      formData.append(
        'media_videos',
        values.media_videos,
        values.media_videos.name,
      );
    } else if (
      values.media_videos &&
      values.media_videos.rawFile instanceof File
    ) {
      formData.append(
        'media_videos',
        values.media_videos.rawFile,
        values.media_videos.rawFile.name,
      );
    }
    try {
      const response = await fetch(`${environment.apiUrl}/article`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
        },
        body: formData,
    });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Failed to create article:', error);
    }
  };
  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="titre" />
        <TextInput source="description" />
        <FileInput source="media_images" accept="image/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="media_videos" accept="video/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="auteur" />
        <SelectInput
          source="type_article_id"
          choices={typeChoices}
          optionText="name"
          optionValue="id"
        />
        <TextInput source="contenu" multiline />
      </SimpleForm>
    </Create>
  );
};
